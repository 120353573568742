<template>
  <v-list nav>
    <template v-for="(item, i) in myItems.filter((f) => f.visible == true)">
      <v-list-group
        exact
        :key="i"
        :prepend-icon="item.icon"
        v-if="item.children"
        v-model="item.active"
      >
        <template v-slot:activator>
          <v-list-item-content class="mx-n4">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          active-class="primary"
          color="white"
          v-for="(subItem, ind) in item.children.filter(
            (f) => f.visible == true
          )"
          :key="ind"
          :to="subItem.to"
        >
          <v-list-item-icon></v-list-item-icon>
          <v-list-item-title class="mx-n4">
            <v-icon small class="ml-1">mdi-circle-outline</v-icon>
            {{ subItem.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-item
        v-else
        :to="item.to"
        :key="i"
        active-class="primary"
        color="white"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="mx-n4">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      items: [],
    };
  },

  created() {
    this.setItems();
    setTimeout(() => {
      var childs = this.myItems.filter((item) => {
        return (
          item.children &&
          item.children.some((it) => {
            return it.to == this.$route.path;
          })
        );
      });
      console.log(childs);

      if (childs && childs.length > 0) {
        childs[0].active = true;
      }
    }, 200);
  },

  computed: {
    myItems() {
      var list = [
        {
          title: this.$t("Dashboard"),
          icon: "mdi-view-dashboard-outline",
          group: "users",
          visible: this.$store.getters.isInRole(0),
          children: [
            {
              active: false,
              title: "إدارة المستخدمين",
              to: "/users",
              visible: this.$store.getters.isInRole(0),
            },
            {
              active: false,
              title: " إدارة الصلاحيات",
              to: "/groups",
              visible: this.$store.getters.isInRole(0),
            },
          ],
        },
        {
          title: this.$t("products.products"),
          icon: "mdi-clipboard-list-outline",
          visible: this.$store.getters.isInRole(1),
          to: "/products",
        },
        {
          title: this.$t("customers.customers"),
          icon: "mdi-account-multiple-outline",
          visible: this.$store.getters.isInRole(7),
          to: "/customers",
        },
        {
          title: this.$t("customersProduct.customersProduct"),
          icon: "mdi-account-key-outline",
          visible: this.$store.getters.isInRole(11),
          to: "/customersProduct",
        },
        {
          title: this.$t("activations.activations"),
          icon: "mdi-database-lock-outline",
          visible: this.$store.getters.isInRole(16),
          to: "/activations",
        }, 
        {
          title: this.$t("activationsReport"),
          icon: "mdi-chart-bar",
          visible: this.$store.getters.isInRole(17),
          to: "/activationsReport",
        },
      ];
      return list;
    },
  },

  methods: {
    setItems() {
      this.items.push(
        {
          title: this.$t("Dashboard"),
          icon: "mdi-view-dashboard-outline",
          group: "users",
          visible: this.$store.getters.isInRole(0),
          children: [
            {
              active: false,
              title: "إدارة المستخدمين",
              to: "/users",
              visible: this.$store.getters.isInRole(0),
            },
            {
              active: false,
              title: " إدارة الصلاحيات",
              to: "/groups",
              visible: this.$store.getters.isInRole(0),
            },
          ],
        },
        {
          title: this.$t("products.products"),
          icon: "mdi-clipboard-list-outline",
          visible: this.$store.getters.isInRole(1),
          to: "/products",
        },
        {
          title: this.$t("customers.customers"),
          icon: "mdi-account-multiple-outline",
          visible: this.$store.getters.isInRole(7),
          to: "/customers",
        },
        {
          title: this.$t("customersProduct.customersProduct"),
          icon: "mdi-account-key-outline",
          visible: this.$store.getters.isInRole(11),
          to: "/customersProduct",
        },
        {
          title: this.$t("activations.activations"),
          icon: "mdi-database-lock-outline",
          visible: this.$store.getters.isInRole(16),
          to: "/activations",
        }  
        ,{
          title: this.$t("activationsReport"),
          icon: "mdi-chart-bar",
          visible: this.$store.getters.isInRole(17),
          to: "/activationsReport",
        }
      );
    },
  },
  beforeCreate() {},
};
</script>
