<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters.isInRole(8)"
          color="primary"
          dark
          class="mb-2"
          @click="dialog = true"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-toolbar>

      <data-table-component
        :headers="headers"
        :items="customers"
        :search="search"
        :loading="loading"
      />
    </v-card>

    <Dialog
      :onSave="save"
      :onClose="close"
      :dialog="dialog"
      :editedItem="editedItem"
      :editedIndex="editedIndex"
      :valid="valid"
      :resetValidation="resetValidation"
      @refreshTable="refreshTable"
    />
    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import Dialog from "./Dialog.vue";
import DataTableComponent from "../../components/DataTableComponent.vue";
export default {
  components: { DataTableComponent, ConfirmDialog, Dialog },
  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",

      customers: [],
      editedItem: { productType: 0 },
      defaultItem: { productType: 0 },
      resetValidation: 0,
    };
  },

  computed: {
    headers() {
      var list = [
        { text: this.$t("customers.companyName"), value: "companyName" },
        { text: this.$t("customers.customerName"), value: "customerName" },
        {
          text: this.$t("customers.customerAddress"),
          value: "customerAddress",
        },
        { text: this.$t("customers.customerPhone"), value: "customerPhone" },
        {
          text: this.$t("createdBy"),
          value: "createdUser.userName",
          secondLine:{
            value: "createdDate",
            format: "YYYY/MM/DD hh:mm A",
          }
        },
         {
          text: this.$t("modifiedBy"),
          value: "modifiedUser.userName",
          secondLine:{
            value: "modifiedDate",
            format: "YYYY/MM/DD hh:mm A",
          }
        },
      ];

      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };
      if (this.$store.getters.isInRole(9)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(10)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
  },
  created() {
    this.loading = true;
    this.refreshTable();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.customers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.customers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.customers[this.editedIndex];
      this.$axios
        .delete("Customers/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      this.$axios
        .get("Customers/Get")
        .then((response) => {
          this.customers = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.customers[this.editedIndex];
          this.$axios
            .put("Customers/Update?guid=" + updatedItem.guid, this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
          Object.assign(this.users[this.editedIndex], this.editedItem);
        } else {
          this.$axios
            .post("Customers/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
