import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import axios from "axios";
import jwt_decode from "jwt-decode";
Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    drawer:
      localStorage.getItem("drawer") == null
        ? true
        : localStorage.getItem("drawer") == "true",

    idToken: localStorage.getItem("token"),
    expiration: localStorage.getItem("expiration"),
    overlay: false,
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload;
      localStorage.setItem("drawer", payload);
    },

    authUser(state, userData) {
      state.idToken = userData.token;
      state.expiration = userData.expiration;
    },
    clearAuth(state) {
      state.idToken = null;
      state.expiration = null;
    },
    overlay(state, overlay) {
      state.overlay = overlay;
    },
  },
  getters: {
    isLoading(state) {
      return state.overlay;
    },
    isInRole: (state) => (role) => {
      //return true;
      var is = jwt_decode(state.idToken).role.some((s) => s == role);
      return is;
    },
    userName(state) {
      // console.log(jwt_decode(state.idToken));
      return jwt_decode(state.idToken).unique_name;
    },
  },
  actions: {
    login({ commit }, userData) {
      localStorage.setItem("token", userData.accessToken);
      localStorage.setItem("expiration", userData.expiration);
      commit("authUser", {
        token: userData.accessToken,
        expiration: userData.expiration,
      });
      axios.defaults.headers.get["Authorization"] =
        "Bearer " + localStorage.getItem("token");
      router.push({
        name: "Dashboard",
      });
    },
    overlay({ commit }, overlay) {
      commit("overlay", overlay);
    },

    logout({ commit }) {
      commit("clearAuth");
      //localStorage.clear();

      if (this.$vuetify) {
        localStorage.setItem("dark", this.$vuetify.theme.dark);
        this.$vuetify.rtl = localStorage.getItem("lang") == "ar" ? true : false;
      }
      if (this.$i18n) {
        this.$i18n.locale = this.$i18n.locale == "ar" ? "ar" : "en";
        localStorage.setItem("lang", this.$i18n.locale);
      }
      localStorage.setItem("token", null);
      localStorage.setItem("userId", null);
      localStorage.setItem("fullname", null);
      localStorage.setItem("expiration", null);
      axios.defaults.headers.get["Authorization"] = null;
    },
  },
});
