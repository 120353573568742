import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary2: "#0C65AF",
        primary: "#009688",
        secondary: "#EC407A",
        accent: "#9C27b0",
        info: "#00CAE3",
      },
      dark: {
        primary2: "#0C65AF",
        primary: "#009688",
        secondary: "#EC407A",
        accent: "#9C27b0",
        info: "#00CAE3",
      },
    },
  },
});
