<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="">
            {{ $t("mostProductActivation") }}
          </v-card-title>
          <apexchart
            type="bar"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" md="6">
        <v-card>
          <v-card-title class=""> التفعيلات الفعالة والمنتهية </v-card-title>
          <apexchart
            type="pie"
            :options="optionsActivationByCategory"
            :series="seriesActivationByCategory"
          ></apexchart>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader
          :loading="skeletonLoader"
          translate="trans"
          type="table"
        >
          <material-card color="teal lighten-1" full-header class="mt-5">
            <template #heading>
              <v-tabs
                v-model="tab"
                align-with-title
                background-color="transparent"
                class="pa-0 ma-0"
              >
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <span
                  class="subheading font-weight-light mx-3"
                  style="align-self: center"
                >
                  <v-btn
                    icon
                    :title="$t('refresh')"
                    @click="getActivationByType()"
                  >
                    <v-icon>mdi-cached</v-icon>
                  </v-btn>
                </span>
                <v-tab v-for="tab in tabs" :key="tab.value" class="white--text">
                  {{ tab.text }}
                </v-tab>
              </v-tabs>
            </template>
            <data-table-component
              :headers="headers"
              :items="activations"
              :search="search"
              :loading="loading"
            />
          </material-card>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTableComponent from "../components/DataTableComponent.vue";
import MaterialCard from "../components/base/MaterialCard.vue";
export default {
  components: { DataTableComponent, MaterialCard },
  data() {
    return {
      search: "",
      loading: false,
      mostActiveProducts: [],
      activationByCategory: [],
      activations: [],
      tab: 0,
      skeletonLoader: true,
    };
  },
  computed: {
    tabs() {
      return [
        { text: this.$t("ExpireSoon"), value: 0 },
        { text: this.$t("Expired"), value: 1 },
        { text: this.$t("Active"), value: 2 },
      ];
    },
    activeStatusItems() {
      return [
        { text: this.$t("Active"), value: "Active", color: "blue white--text" },
        {
          text: this.$t("Expired"),
          value: "Expired",
          color: "red darken-1 white--text",
        },
        { text: this.$t("ExpireSoon"), value: "ExpireSoon", color: "warning" },
      ];
    },
    headers() {
      var list = [
        { text: this.$t("products.productName"), value: "productName" },
        { text: this.$t("customers.customerName"), value: "customerName" },
        { text: this.$t("activations.machineName"), value: "machineName" },
        { text: this.$t("activations.machineCode"), value: "machineCode" },
        { text: this.$t("activations.macAddress"), value: "macAddress" },

        {
          text: this.$t("date"),
          value: "createdDate",
          type: "date",
          format: "YYYY/MM/DD hh:mm a",
        },
        {
          text: this.$t("activations.currentVersion"),
          value: "currentVersion",
        },
        {
          text: this.$t("activations.lastCheckDate"),
          value: "lastCheckDate",
          type: "date",
          format: "YYYY/MM/DD hh:mm a",
        },
        { text: this.$t("activations.extraInfo"), value: "extraInfo" },
        // {
        //   text: this.$t("activations.activeStatus"),
        //   value: "activeStatus",
        //   type: "select",
        //   items: this.activeStatusItems,
        //   chip: true,
        // },
      ];
      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };

      // actions.templates.push({
      //   type: "btn",
      //   icon: "mdi-clipboard-check-outline",
      //   text: this.$t("activations.copyLicense"),
      //   click: this.copyLicense,
      // });
      // if (this.$store.getters.isInRole(17)) {
      //   actions.templates.push({
      //     type: "btn",
      //     icon: "mdi-delete",
      //     text: this.$t("delete"),
      //     click: this.deleteItem,
      //     color: "red darken-2",
      //   });
      // }
      list.push(actions);
      return list;
    },
    series() {
      var list = [
        {
          name: "  " + this.$t("count") + "  ",
          data: this.mostActiveProducts.map(function (item) {
            return item["count"];
          }),
        },
      ];
      return list;
    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "bar",
        },
        colors: ["#26a69a"],

        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: this.$vuetify.theme.isDark ? ["#fff"] : ["#304758"],
          },
        },

        xaxis: {
          categories: this.mostActiveProducts.map(function (item) {
            return item["productName"];
          }),
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      };
    },

    labelsActivationByCategory() {
      var transLabel = [];
      var labels = this.activationByCategory.map(function (item) {
        return item["category"];
      });
      for (let index = 0; index < labels.length; index++) {
        transLabel.push(this.$t(labels[index]));
      }
      return transLabel;
    },

    seriesActivationByCategory() {
      var data = this.activationByCategory.map(function (item) {
        return item["count"];
      });
      return data;
    },
    optionsActivationByCategory() {
      return {
        chart: {
          type: "donut",
          height: 360,
        },
        labels: this.labelsActivationByCategory,
        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 200,
        //       },
        //       legend: {
        //         position: "bottom",
        //       },
        //     },
        //   },
        // ],
      };
    },
  },
  watch: {
    tab(val) {
      console.log(val);
      this.getActivationByType();
    },
  },
  created() {
    this.getMostActiveProducts();
    this.getActivationByCategory();
    //this.getActivationByType();
  },
  methods: {
    getMostActiveProducts() {
      this.$axios
        .get("Dashboard/GetMostActiveProducts")
        .then((response) => {
          this.mostActiveProducts = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
    },
    getActivationByCategory() {
      this.$axios
        .get("Dashboard/GetActivationByCategory")
        .then((response) => {
          this.activationByCategory = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.skeletonLoader = false;
        });
    },
    getActivationByType() {
      this.$axios
        .get("Dashboard/GetActivationByType?type=" + this.tab)
        .then((response) => {
          this.activations = response.data.data;
          console.log(this.activations);
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
    },
    getCustomers() {
      this.$axios
        .get("Customers/Get")
        .then((response) => {
          this.customers = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
    },

    edit(item) {
      this.editedItem.productGuid = item.guid;
      this.dialog = true;

      this.$axios
        .get("Products/GetFeatures?productGuid=" + item.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.features = response.data.data;
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },
    save() {
      this.$axios
        .post("CustomersProducts/Add", this.editedItem)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
            this.close();
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });
    },
  },
};
</script>

<style></style>
