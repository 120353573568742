<template>
  <v-navigation-drawer
    v-model="drawer"
    id="core-navigation-drawer"
    :right="$vuetify.rtl"
    app
    width="270"
    v-bind="$attrs"
    style="z-index: 111"
  >
    <v-list dense nav class="ma-0 pa-0">
      <v-list-item class="ma-0 pa-0">
        <v-btn
          style="padding: 31px !important"
          class="ma-0 white--text"
          min-width="100%"
          min-height="100%"
          color="primary"
          tile
          depressed
          to="/"
        >
          <span dir="auto" class="pt-1 mx-1" style="font-size: 16px">
            {{ ($App_Settings.Title?$App_Settings.Title: AppTitleName) }}
          </span>
        </v-btn>
      </v-list-item>
    </v-list>

    <itemDrawer />
  </v-navigation-drawer>
</template>

<script>
// Utilities
import ItemDrawer from "./ItemDrawer.vue";

export default {
  name: "DashboardCoreDrawer",
  components: { ItemDrawer },

  data() {
    ItemDrawer;
    return {
      AppTitleName:"Product Activation"
    };
  },
  watch: {},
  created() {},
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },

    profile() {
      return {
        avatar: true,
      };
    },
  },
};
</script>
