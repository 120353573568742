<template>
  <v-container fluid>
    <product-filter
      windowType="activationsReport"
      :allDatesValue="false"
      :panel="true"
      @filterChange="filterChange"
      :json_config="json_config"
    />
    <v-card class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" dark class="mb-2" @click="dialog = true">
            {{ $t("add") }}
            <v-icon> mdi-plus </v-icon>
          </v-btn> -->
      </v-toolbar>

      <data-table-component
        :headers="headers"
        :items="activations"
        :search="search"
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>
<script>
import DataTableComponent from "../../components/DataTableComponent.vue";
import ProductFilter from "../../components/ProductFilter.vue";
export default {
  components: { DataTableComponent, ProductFilter },
  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",

      activations: [],
      editedItem: { productType: 0 },
      defaultItem: { productType: 0 },
      resetValidation: 0,
      filter: null,
      json_config: {
        showExcelBtn: true,
        excelBtnText: this.$t("download_excel"),
        fileName: "ActivationReport.xls",
        worksheetName: "Activation Report",
        json_data: [],
        json_fields: {},
      },
    };
  },

  computed: {
    activeStatusItems() {
      return [
        { text: this.$t("Active"), value: "Active", color: "blue white--text" },
        {
          text: this.$t("Expired"),
          value: "Expired",
          color: "red darken-1 white--text",
        },
        { text: this.$t("ExpireSoon"), value: "ExpireSoon", color: "warning" },
      ];
    },
    headers() {
      var list = [
        { text: this.$t("products.product"), value: "productName" },
        { text: this.$t("customers.customerName"), value: "customerName" },
        { text: this.$t("activations.machineName"), value: "machineName" },
        {
          text: this.$t("activations.machineCode"),
          value: "machineCode",
          cellClass: "text_max_width",
        },
        { text: this.$t("activations.macAddress"), value: "macAddress" },
        {
          text: this.$t("activations.currentVersion"),
          value: "currentVersion",
        },
        {
          text: this.$t("activations.lastCheckDate"),
          value: "lastCheckDate",
          type: "date",
          format: "YYYY/MM/DD hh:mm a",
        },
        { text: this.$t("activations.extraInfo"), value: "extraInfo" },
        {
          text: this.$t("activeDate"),
          value: "activeDate",
          type: "date",
          format: "YYYY/MM/DD hh:mm a",
        },
        {
          value: "licenseCode",
          align: " d-none",
        },
        {
          text: this.$t("createdBy"),
          value: "customerProductCreatedUserName",
          secondLine: {
            value: "customerProductCreatedDate",
            format: "YYYY/MM/DD hh:mm A",
          },
        },
        {
          text: this.$t("modifiedBy"),
          value: "customerProductModifiedUserName",
          secondLine: {
            value: "customerProductModifiedDate",
            format: "YYYY/MM/DD hh:mm A",
          },
        },
        {
          text: this.$t("activations.activeStatus"),
          value: "activeStatus",
          type: "select",
          items: this.activeStatusItems,
          chip: true,
        },
        {
          text: "",
          value: "actions",
          templates: [
            {
              type: "btn",
              icon: "mdi-shield-key-outline",
              text: this.$t("serialNumber"),
              click: this.copySerialNumber,
            },
            {
              type: "btn",
              icon: "mdi-clipboard-check-outline",
              text: this.$t("activations.licenseCode"),
              click: this.copyLicenseCode,
            },
          ],
        },
      ];

      return list;
    },
  },
  created() {
    this.loading = true;
    this.refreshTable();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    async copySerialNumber(item) {
      try {
        await navigator.clipboard.writeText(item.serialNumber);
        this.$toast.success(this.$t("CopySuccess"));
      } catch ($e) {
        this.$toast.error(this.$t("CannotCopy"));
      }
    },
    async copyLicenseCode(item) {
      try {
        await navigator.clipboard.writeText(item.licenseCode);
        this.$toast.success(this.$t("CopySuccess"));
      } catch ($e) {
        this.$toast.error(this.$t("CannotCopy"));
      }
    },
    filterChange(filter) {
      this.filter = filter;
      this.refreshTable();
    },
    refreshTable() {
      var url = `Activations/ActivationReport?fromDate=${this.filter.fromDate}&toDate=${this.filter.toDate}`;
      if (this.filter.productName) {
        url += `&productGuid=${this.filter.productName}`;
      }
      if (this.filter.customerName) {
        url += `&customerGuid=${this.filter.customerName}`;
      }
      if (this.filter.machineCode) {
        url += `&machineCode=${this.filter.machineCode}`;
      }
      if (this.filter.serialNumber) {
        url += `&serialNumber=${this.filter.serialNumber}`;
      }
      if (this.filter.allDates) {
        url += `&allDates=${this.filter.allDates}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          this.activations = response.data.data;

          this.activations.forEach((element) => {
            element.productName =
              element.productDisplayName + " - " + element.productName;
          });
          this.headers.forEach((header) => {
            if (header.text) {
              this.json_config.json_fields[header.text] = header.value;
            }
          });

          this.json_config.json_data = this.activations;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.text_max_width {
  max-width: 160px;
}
</style>
