var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('loading'),"page":_vm.page,"items-per-page":_vm.itemsPerPage2 != null ? (_vm.itemsPerPage = _vm.itemsPerPage2) : _vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i,class:_vm.$vuetify.breakpoint.xsOnly
              ? ' v-data-table__mobile-table-row'
              : '',on:{"click":function($event){return _vm.$emit('rowClick', item)}}},[_vm._l((_vm.headers.filter(function (f) { return f.value != 'actions'; })),function(header){return _c('td',{key:header.value,class:(header.cellClass ? header.cellClass : ' ') +
              (_vm.$vuetify.breakpoint.xsOnly
                ? ' v-data-table__mobile-row'
                : ' text-start'+header.align)},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v(" "+_vm._s(header.text)+" ")]),(header.type == 'select')?_c('span',[(header.chip)?_c('v-chip',{attrs:{"color":_vm.getSelectcolor(item[header.value], header)}},[_vm._v(" "+_vm._s(_vm.getSelectText(item[header.value], header))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getSelectText(item[header.value], header))+" ")])],1):(header.type == 'multiselect')?_c('div',{staticClass:"pa-2"},_vm._l((item[header.value]),function(guid,ind){return _c('v-chip',{key:ind,staticClass:"ml-1 mt-1"},[_vm._v(" "+_vm._s(_vm.getSelectText(guid, header))+" ")])}),1):(header.type == 'switch')?_c('v-switch',{attrs:{"readonly":header.readonly},model:{value:(item[header.value]),callback:function ($$v) {_vm.$set(item, header.value, $$v)},expression:"item[header.value]"}}):(header.type == 'textfield')?_c('v-text-field',{attrs:{"rules":[_vm.rules.required]},model:{value:(item[header.value]),callback:function ($$v) {_vm.$set(item, header.value, $$v)},expression:"item[header.value]"}}):(header.type == 'email')?_c('v-text-field',{attrs:{"rules":[_vm.rules.email]},model:{value:(item[header.value]),callback:function ($$v) {_vm.$set(item, header.value, $$v)},expression:"item[header.value]"}}):(header.type == 'checkbox')?_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item[header.value]),callback:function ($$v) {_vm.$set(item, header.value, $$v)},expression:"item[header.value]"}}):(header.type == 'date')?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item[header.value],header.format))+" ")]):(header.type == 'number')?_c('span',[_vm._v(" "+_vm._s(_vm._f("number")(item[header.value],header.format))+" ")]):(header.align!=' d-none')?_c('span',[_vm._v(" "+_vm._s(_vm.getPropertyByPath(item, header.value))+" "),_c('div',[_vm._v(_vm._s(_vm.getSecondLine(item, header)))])]):_vm._e()],1)}),(_vm.actionsHeader)?_c('td',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row'
                : 'text-end',style:(_vm.$vuetify.breakpoint.xsOnly ? '' : 'width: 175px')},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v(" "+_vm._s(_vm.$t("actions")))]),_c('div',[_vm._l((_vm.actionsHeader.templates),function(template,j){return [(template.type == 'btn')?_c('v-tooltip',{key:j,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":template.color},on:{"click":function($event){return template.click(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(template.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(template.text))])]):_vm._e()]})],2)]):_vm._e()],2)}),0),(
          _vm.footerItems && _vm.footerItems.filter(function (f) { return f.showSummary; }).length > 0
        )?_c('tfoot',[_c('tr',{staticClass:"primary--text",class:_vm.$vuetify.breakpoint.xsOnly
              ? ' v-data-table__mobile-table-row'
              : ''},[_vm._l((_vm.footerItems),function(item,i){return [(i == 0)?_c('td',{key:i,staticClass:"d-none d-sm-flex pa-3"},[_vm._v(" "+_vm._s(_vm.$t("total"))+" ")]):(!item.showSummary)?_c('td',{key:i,class:_vm.$vuetify.breakpoint.xsOnly ? 'd-none' : ''}):_c('td',{key:i,class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''},[_c('span',{staticClass:"hidden-sm-and-up"},[_vm._v(" "+_vm._s(_vm.$t("total") + " " + item.text)+" ")]),_vm._v(" "+_vm._s(_vm._f("number")(_vm.sumTotals(item.value),"0,0"))+" ")])]})],2)]):_vm._e()]}}])}),_c('v-divider'),(_vm.showFooter == null ? true : false)?_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","sm":"2","align-self":"center"}},[_c('span',{staticClass:"px-5"},[_vm._v(" "+_vm._s(_vm.$t("count"))+" : "+_vm._s(_vm.items && _vm.items.length > 0 ? _vm.items.length : "")+" ")])]),_c('v-col',{attrs:{"align-self":"end","cols":"12","sm":"8"}},[_c('v-pagination',{staticClass:"mb-2",attrs:{"total-visible":7,"length":_vm.pageLength ? _vm.pageLength : 0},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-col',{attrs:{"align-self":"center","cols":"12","sm":"2"}},[_c('v-select',{attrs:{"dense":"","outlined":"","label":_vm.$t('itemPerPage'),"items":_vm.itemPerPageSelect,"item-text":"text","item-value":"value","hide-details":""},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }