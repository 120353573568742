<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          <v-card-title>
            <span class="">
              {{
                (editedIndex === -1 ? $t("add") : $t("edit")) +
                " " +
                $t("customersProduct.activate")
              }}
            </span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="editedItem.customerGuid"
                  :rules="[rules.required]"
                  :label="$t('customers.customerName')"
                  :items="customers"
                  item-text="customerDisplayName"
                  item-value="guid"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="productGuid"
                  :rules="[rules.required]"
                  :label="$t('products.product')"
                  :items="products"
                  item-text="productName"
                  item-value="guid"
                  return-object
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="12">
                <v-autocomplete
                  v-model="activateFeaturesGuid"
                  chips
                  multiple
                  :items="features"
                  :label="$t('features.features')"
                  item-text="featureName"
                  item-value="guid"
                >
                  <!-- <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.featureName }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ editedItem.activateFeaturesGuid.length - 1 }}
                      others)
                    </span>
                  </template> -->
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  type="number"
                  min="0"
                  v-model="editedItem.numberOfMachines"
                  :label="$t('customersProduct.numberOfMachines')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  type="number"
                  min="0"
                  v-model="editedItem.expireDays"
                  :label="$t('customersProduct.expireDays')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-switch
                  v-model="editedItem.lifeTime"
                  :label="$t('customersProduct.lifeTime')"
                ></v-switch>
              </v-col>

              <v-col cols="12" md="6">
                <v-switch
                  v-model="editedItem.lockToMachine"
                  :label="$t('customersProduct.lockToMachine')"
                ></v-switch>
              </v-col>

              <v-col cols="12" md="12">
                <v-switch
                  v-model="editedItem.isActive"
                  :label="$t('customersProduct.isActive')"
                ></v-switch>
              </v-col>

              <v-col cols="12" md="6">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="editedItem.supportBeginDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.supportBeginDate"
                      :label="$t('customersProduct.supportBeginDate')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.supportBeginDate"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="red" @click="modal = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(editedItem.supportBeginDate)"
                    >
                      {{ $t("ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  type="number"
                  min="0"
                  v-model="editedItem.supportMonths"
                  :label="$t('customersProduct.supportMonths')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            class="white--text"
            @click="save"
            :disabled="!valid"
            :min-width="100"
          >
            <v-icon>mdi-content-save-outline</v-icon>
            {{ $t("save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="onClose">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: [
    "onSave",
    "onClose",
    "dialog",
    "editedItem",
    "editedIndex",
    "resetValidation",
  ],
  data() {
    return {
      modal: false,
      valid: true,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
      customers: [],
      products: [],
      features: [],
      activateFeaturesGuid: [],
      productGuid: null,
    };
  },
  created() {
    this.$axios.get("Customers/Get").then((response) => {
      this.customers = response.data.data;
    });
    this.$axios.get("Products/Get").then((response) => {
      this.products = response.data.data;
      this.products.forEach(element => {
        element.productName= element.productDisplayName +' - '+element.productName
      });
    });
  },
  watch: {
    resetValidation() {
      this.resetForm();
    },
    productGuid(val) {
      if (val) {
        this.features = val.featuresTemplates;
        //this.getFeatures(val);
      }
    },

    // features() {
    //   this.activateFeaturesGuid = this.editedItem.activateFeaturesGuid;
    // },

    "editedItem.guid": {
      handler: function (newValue) {
        if (newValue) {
          this.productGuid = this.products.filter(
            (f) => f.guid == this.editedItem.productGuid
          )[0];
          this.features = this.productGuid.featuresTemplates;
          if (this.editedItem.supportBeginDate) {
            this.editedItem.supportBeginDate = this.$moment(
              this.editedItem.supportBeginDate
            ).format("yyyy/MM/DD");
          }
          this.activateFeaturesGuid = this.editedItem.activateFeaturesGuid;

          // setTimeout(() => {
          //   this.activateFeaturesGuid = this.editedItem.activateFeaturesGuid;
          //   console.log(this.activateFeaturesGuid);
          // }, 1000);
          //this.getFeatures(this.editedItem.productGuid);
          // this.$axios
          //   .get(
          //     "Products/GetFeatures?productGuid=" + this.editedItem.productGuid
          //   )
          //   .then((response) => {
          //     this.features = response.data.data;
          //   });
        }
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    getFeatures(guid) {
      this.$axios
        .get("Products/GetFeatures?productGuid=" + guid)
        .then((response) => {
          this.features = response.data.data;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        this.editedItem.productGuid = this.productGuid.guid;
        this.editedItem.activateFeaturesGuid = this.activateFeaturesGuid;
        if (this.editedIndex > -1) {
          this.$axios
            .put(
              "CustomersProducts/Update?guid=" + this.editedItem.guid,
              this.editedItem
            )
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.onClose();
                this.$emit("refreshTable");
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.$emit("refreshTable");
              console.log(e);
            });
        } else {
          this.$axios
            .post("CustomersProducts/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.onClose();
                this.$emit("refreshTable");
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.$emit("refreshTable");
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
