<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters.isInRole(2)"
          color="primary"
          dark
          class="mb-2"
          @click="dialog = true"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-toolbar>

      <data-table-component
        :headers="headers"
        :items="products"
        :search="search"
        :loading="loading"
      />
    </v-card>

    <Dialog
      :onSave="save"
      :onClose="close"
      :dialog="dialog"
      :editedItem="editedItem"
      :editedIndex="editedIndex"
      :valid="valid"
      :resetValidation="resetValidation"
      @refreshTable="refreshTable"
    />
    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    />

    <v-dialog v-model="dialogFeatures" persistent max-width="600px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-toolbar color="primary darken-1" dark>
            <v-card-title>
              <span class="">{{
                $t("edit") + " " + $t("features.features")
              }}</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <!-- <v-row>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="features"
                    :label="$t('features.features')"
                    :items="features"
                    item-text="featureName"
                    item-value="guid"
                  ></v-select>
                </v-col>
              </v-row> -->

            <!-- <v-list dense subheader>
              <v-subheader>{{ $t("features.features") }}</v-subheader>
              <v-list-item-group color="primary">
                <v-row>
                  <v-col cols="6" v-for="(item, i) in features" :key="i">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-text-field
                            dense
                            hide-details=""
                            v-model="item.featureName"
                          ></v-text-field>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-list-item-group>
            </v-list> -->

            <v-simple-table fixed-header height="400">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="">
                      {{ $t("features.featureName") }}
                    </th>
                    <th class="">
                      {{ $t("notes") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in features" :key="item.guid">
                    <td>
                      <v-text-field
                        dense
                        hide-details
                        v-model="item.featureName"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        dense
                        hide-details
                        v-model="item.note"
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              class="white--text"
              @click="saveFeatures"
              :disabled="!valid"
              :min-width="100"
            >
              <v-icon>mdi-content-save-outline</v-icon>
              {{ $t("save") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="closeFeatures">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import Dialog from "./Dialog.vue";
import DataTableComponent from "../../components/DataTableComponent.vue";
export default {
  components: { DataTableComponent, ConfirmDialog, Dialog },
  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      dialogFeatures: false,
      editedIndex: -1,
      search: "",

      products: [],
      features: [],
      editedItem: { productType: 0 },
      defaultItem: { productType: 0 },
      resetValidation: 0,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },

  computed: {
    productTypes() {
      return [
        { text: this.$t("products.productTypes.windowsForms"), value: 0 },
        { text: this.$t("products.productTypes.web"), value: 1 },
        { text: this.$t("products.productTypes.mobile"), value: 2 },
        { text: this.$t("products.productTypes.mix"), value: 3 },
        { text: this.$t("products.productTypes.other"), value: 4 },
      ];
    },
    headers() {
      var list = [
        { text: this.$t("products.productDisplayName"), value: "productDisplayName" },
        { text: this.$t("products.productName"), value: "productName" },
        {
          text: this.$t("products.productType"),
          value: "productType",
          type: "select",
          items: this.productTypes,
        },
        { text: this.$t("notes"), value: "note" },
        {
          text: this.$t("products.isActive"),
          value: "isActive",
          type: "switch",
          readonly: true,
        },
        {
          text: this.$t("createdBy"),
          value: "createdUser.userName",
          secondLine:{
            value: "createdDate",
            format: "YYYY/MM/DD hh:mm A",
          }
        },
         {
          text: this.$t("modifiedBy"),
          value: "modifiedUser.userName",
          secondLine:{
            value: "modifiedDate",
            format: "YYYY/MM/DD hh:mm A",
          }
        },
      ];

      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };
      if (this.$store.getters.isInRole(6)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-folder-star-outline",
          text: this.$t("features.features"),
          click: this.editFeatures,
        });
      }
      if (this.$store.getters.isInRole(3)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(4)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
  },
  created() {
    this.loading = true;
    this.refreshTable();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    editFeatures(item) {
      this.$axios
        .get("Products/GetFeatures?productGuid=" + item.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.features = response.data.data;
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
        });

      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogFeatures = true;
    },
    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.products[this.editedIndex];
      this.$axios
        .delete("Products/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeFeatures() {
      this.dialogFeatures = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      this.$axios
        .get("Products/Get")
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.products[this.editedIndex];
          this.$axios
            .put("Products/Update?guid=" + updatedItem.guid, this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
          Object.assign(this.users[this.editedIndex], this.editedItem);
        } else {
          this.$axios
            .post("Products/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        }
      }
    },
    saveFeatures() {
      this.$axios
        .put(
          "Products/UpdateFeatures?productGuid=" + this.editedItem.guid,
          this.features
        )
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
            this.closeFeatures();
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          // this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          // this.refreshTable();
          console.log(e);
        });
    },
  },
};
</script>

<style></style>
