<template>
  <loading-screen v-if="load" />
  <router-view :key="$route.path" v-else />
</template>

<script>
import LoadingScreen from "./components/loadingScreen.vue";

export default {
  components: {
    LoadingScreen,
  },
  name: "App",
  data() {
    return {
      load: true,
    };
  },
  mounted() {
    //
    setTimeout(() => {
      if(this.$App_Settings){
        this.$vuetify.theme.themes.light.primary = this.$App_Settings.Color;
      }
      this.load = false;
    }, 1200);
  },
};
</script>

<style>
@font-face {
  font-family: "Frutiger";
  src: local("Frutiger"),
    url("./fonts/Bahij_TheSansArabic-SemiBold.ttf") format("truetype");
}
.v-application {
  font-family: "Frutiger" !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
::-webkit-scrollbar-track-piece {
  background: rgb(71, 71, 71);
}
::-webkit-scrollbar-corner {
  background: rgb(49, 49, 49);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
