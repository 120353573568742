<template>
  <v-container fluid>
    <ProductFilter
      windowType="customersProduct"
      :panel="true"
      @filterChange="filterChange"
      :allDatesValue="true"
      :json_config="json_config"
    />
    <v-card class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters.isInRole(12)"
          color="primary"
          dark
          class="mb-2"
          @click="dialog = true"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-toolbar>

      <data-table-component
        :headers="headers"
        :items="customersProducts"
        :search="search"
        :loading="loading"
      />
    </v-card>

    <Dialog
      :onSave="save"
      :onClose="close"
      :dialog="dialog"
      :editedItem="editedItem"
      :editedIndex="editedIndex"
      :valid="valid"
      :resetValidation="resetValidation"
      @refreshTable="refreshTable"
    />

    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
// import Dialog from "./Dialog.vue";
import DataTableComponent from "../../components/DataTableComponent.vue";
import ProductFilter from "../../components/ProductFilter.vue";
import Dialog from "./Dialog";

export default {
  components: {
    DataTableComponent,
    ConfirmDialog,
    Dialog,
    ProductFilter,
  },
  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",

      products: [],
      customers: [],
      customersProducts: [],
      editedItem: {
        isActive: true,
        lockToMachine: true,
        supportBeginDate: this.$moment(new Date()).format("YYYY/MM/DD"),
        supportMonths: 6,
      },
      defaultItem: {
        isActive: true,
        lockToMachine: true,
        supportBeginDate: this.$moment(new Date()).format("YYYY/MM/DD"),
        supportMonths: 6,
      },
      resetValidation: 0,
      filter: null,
      json_config: {
        showExcelBtn: true,
        excelBtnText: this.$t("download_excel"),
        cssClass: "",
        fileName: "CustomersProducts.xls",
        worksheetName: "Customers Products",
        json_data: [],
        json_fields: {},
      },
    };
  },

  computed: {
    supportStatusItems() {
      return [
        { text: this.$t("Active"), value: "Active", color: "blue white--text" },
        {
          text: this.$t("Expired"),
          value: "Expired",
          color: "red darken-1 white--text",
        },
      ];
    },
    headers() {
      var list = [
        {
          text: this.$t("customers.customerName"),
          value: "customerName",
        },
        {
          text: this.$t("products.product"),
          value: "productName",
        },
        {
          text: this.$t("customersProduct.numberOfMachines"),
          value: "numberOfMachines",
        },
        { text: this.$t("customersProduct.expireDays"), value: "expireDays" },
        {
          text: this.$t("customersProduct.lifeTime"),
          value: "lifeTime",
          type: "switch",
          readonly: true,
        },
        {
          text: this.$t("customersProduct.lockToMachine"),
          value: "lockToMachine",
          type: "switch",
          readonly: true,
        },
        {
          text: this.$t("customersProduct.isActive"),
          value: "isActive",
          type: "switch",
          readonly: true,
        },
        {
          text: this.$t("customersProduct.supportBeginDate"),
          value: "supportBeginDate",
          type: "date",
          format: "YYYY/MM/DD",
        },
        {
          text: this.$t("customersProduct.supportMonths"),
          value: "supportMonths",
        },
        {
          text: this.$t("customersProduct.supportStatus"),
          value: "supportStatus",
          type: "select",
          items: this.supportStatusItems,
          chip: true,
        },
        {
          text: this.$t("createdBy"),
          value: "createdUser.username",
          secondLine: {
            value: "createdDate",
            format: "YYYY/MM/DD hh:mm A",
          },
        },
        {
          text: this.$t("modifiedBy"),
          value: "modifiedUser.username",
          secondLine: {
            value: "modifiedDate",
            format: "YYYY/MM/DD hh:mm A",
          },
        },
        {
          text: this.$t("serialNumber"),
          value: "serialNumber",
          align: " d-none",
        },
      ];

      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };
      if (this.$store.getters.isInRole(15)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-clipboard-check-outline",
          text: this.$t("customersProduct.copySerial"),
          click: this.copySerialNumber,
        });
      }
      if (this.$store.getters.isInRole(13)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(14)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);

      return list;
    },
  },
  created() {
    this.loading = true;
    this.refreshTable();
    // this.getCustomers();
    // this.getProducts();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    async copySerialNumber(item) {
      try {
        await navigator.clipboard.writeText(item.serialNumber);
        this.$toast.success(this.$t("CopySuccess"));
      } catch ($e) {
        this.$toast.error(this.$t("CannotCopy"));
      }
    },
    editItem(item) {
      this.editedIndex = this.customersProducts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.customersProducts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.customersProducts[this.editedIndex];
      this.$axios
        .delete("CustomersProducts/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    filterChange(filter) {
      //console.log(filter);
      this.filter = filter;
      this.refreshTable();
    },
    refreshTable() {
      var url = `CustomersProducts/Get?`;
      if (this.filter) {
        if (this.filter.customerName) {
          url += `customerGuid=${this.filter.customerName}&`;
        }
        if (this.filter.productName) {
          url += `productGuid=${this.filter.productName}&`;
        }
        if (!this.filter.allDates && this.filter.fromDate) {
          url += `fromDate=${this.filter.fromDate}&`;
        }
        if (!this.filter.allDates && this.filter.toDate) {
          url += `toDate=${this.filter.toDate}&`;
        }
        if (this.filter.serialNumber) {
          url += `serialNumber=${this.filter.serialNumber}&`;
        }
      }

      this.$axios
        .get(url)
        .then((response) => {
          this.customersProducts = response.data.data;

          this.headers.forEach((header) => {
            this.json_config.json_fields[header.text] = header.value;
          });

          this.json_config.json_fields[this.$t('createdDate')]= "createdDate";
          this.json_config.json_fields[this.$t('modifiedDate')]= "modifiedDate";


          this.json_config.json_data = this.customersProducts;
          console.log(this.json_config);
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCustomers() {
      this.$axios.get("Customers/Get").then((resp) => {
        var data = resp.data.data;
        for (var i = 0; i < data.length; i++) {
          var customer = data[i];
          this.customers.push({
            text: customer.customerDisplayName,
            value: customer.guid,
          });
        }
      });
    },
    getProducts() {
      this.$axios.get("Products/Get").then((resp) => {
        var data = resp.data.data;
        for (var i = 0; i < data.length; i++) {
          var product = data[i];

          this.products.push({
            text: product.productDisplayName + " - " + product.productName,
            value: product.guid,
          });
        }
      });
    },
    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.customers[this.editedIndex];
          this.$axios
            .put("Customers/Update?guid=" + updatedItem.guid, this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
          Object.assign(this.users[this.editedIndex], this.editedItem);
        } else {
          this.$axios
            .post("Customers/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
