import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { store } from "./store/store";
import i18n from "./i18n";
import "./plugins/base";
import Vue2Filters from "vue2-filters";
import axios from "axios";
import "@mdi/font/css/materialdesignicons.css";
import DatetimePicker from "vuetify-datetime-picker";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSplash from "vue-splash";
// import jwt_decode from "jwt-decode";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueSplash);

const moment = require("moment");
moment.locale(localStorage.getItem("lang") == "en" ? "en" : "ar-dz");
Vue.use(require("vue-moment"), { moment });

Vue.use(Toast, {
  position: localStorage.rtl === "true" ? "bottom-left" : "bottom-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  closeButton: "button",
  icon: true,
  rtl: localStorage.rtl === "true",
});

Vue.use(DatetimePicker);
Vue.use(Vue2Filters);

Vue.config.devtools = false;

Vue.config.productionTip = false;

axios.defaults.withCredentials = true;

if (axios.defaults.baseURL === "" || axios.defaults.baseURL === undefined) {
  axios.get("/static/config.json").then((res) => {
    axios.defaults.baseURL = res.data.VUE_APP_URL;
    Vue.prototype.$App_Settings = res.data.Settings;
  });
}

axios.defaults.headers.get.Accepts = "application/json";
axios.interceptors.request.use(
  (config) => {
    store.commit("overlay", true);

    const token = localStorage.getItem("token");
    const auth = token ? `Bearer ${token}` : "";
    config.headers.common.Authorization = auth;
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  function (response) {
    store.commit("overlay", false);

    return response;
  },
  function (error) {
    store.commit("overlay", false);
    if (
      error.response === undefined ||
      error.response.status === 401 ||
      error.response.status === 403
    ) {
      router.push({ name: "notauthorize" });
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = axios;

new Vue({
  store: store,
  router,
  i18n,
  axios,
  vuetify,
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  // directives: {
  //   print,
  // },
  render: (h) => h(App),
}).$mount("#app");
