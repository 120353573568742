var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{attrs:{"value":_vm.myPanel,"multiple":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.$t("searchPanel"))+" ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.customers,"clearable":"","label":_vm.$t('customers.customerName'),"item-text":"customerDisplayName","item-value":"guid","return-object":false,"hide-details":"","outlined":"","dense":""},model:{value:(_vm.customerName),callback:function ($$v) {_vm.customerName=$$v},expression:"customerName"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.products,"clearable":"","label":_vm.$t('products.product'),"item-text":"productDisplayName","item-value":"guid","return-object":false,"hide-details":"","outlined":"","dense":""},model:{value:(_vm.productName),callback:function ($$v) {_vm.productName=$$v},expression:"productName"}})],1)],1),(
          _vm.windowType == 'activationsReport' ||
          _vm.windowType == 'customersProduct'
        )?_c('v-row',[_c('v-col',[_c('v-dialog',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":_vm.allDates,"hide-details":"","outlined":"","rules":[_vm.required],"dense":"","label":_vm.$t('fromDate'),"persistent-hint":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":"","title":_vm.$t('allDates')},model:{value:(_vm.allDates),callback:function ($$v) {_vm.allDates=$$v},expression:"allDates"}})]},proxy:true}],null,true),model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}],null,false,3737236834),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1)],1),_c('v-col',[_c('v-dialog',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":_vm.allDates,"hide-details":"","outlined":"","dense":"","rules":[_vm.required],"label":_vm.$t('toDate'),"persistent-hint":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}],null,false,3987552965),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1),_c('v-col',[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","label":_vm.$t('serialNumber'),"persistent-hint":""},model:{value:(_vm.serialNumber),callback:function ($$v) {_vm.serialNumber=$$v},expression:"serialNumber"}})],1),(_vm.windowType == 'activationsReport')?_c('v-col',[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","label":_vm.$t('machineCode'),"persistent-hint":""},model:{value:(_vm.machineCode),callback:function ($$v) {_vm.machineCode=$$v},expression:"machineCode"}})],1):_vm._e()],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card-actions',[_c('v-btn',{staticClass:"me-5",attrs:{"disabled":(_vm.windowType == 'activationsReport' ||
                  _vm.windowType == 'customersProduct') &&
                (!_vm.fromDate || !_vm.toDate),"color":"primary"},on:{"click":function($event){return _vm.filterChange()}}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1),(_vm.json_config && _vm.json_config.showExcelBtn)?_c('downloadExcel',{class:_vm.json_config.cssClass,attrs:{"data":_vm.json_config.json_data,"fields":_vm.json_config.json_fields,"worksheet":_vm.json_config.worksheetName,"name":_vm.json_config.fileName}},[_c('v-btn',{attrs:{"color":"secondary","disabled":!_vm.json_config.json_data || _vm.json_config.json_data.length == 0}},[_vm._v(" "+_vm._s(_vm.json_config.excelBtnText)+" "),_c('v-icon',[_vm._v(" mdi-file-excel ")])],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }