<template>
  <v-container fluid>
    <product-filter :panel="true" @filterChange="filterChange" />
    <v-card class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" dark class="mb-2" @click="dialog = true">
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn> -->
      </v-toolbar>

      <data-table-component
        :headers="headers"
        :items="activations"
        :search="search"
        :loading="loading"
      />
    </v-card>

    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import DataTableComponent from "../../components/DataTableComponent.vue";
import ProductFilter from "../../components/ProductFilter.vue";
export default {
  components: { DataTableComponent, ConfirmDialog, ProductFilter },
  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",

      activations: [],
      editedItem: { productType: 0 },
      defaultItem: { productType: 0 },
      resetValidation: 0,
      filter: null,
    };
  },

  computed: {
    activeStatusItems() {
      return [
        { text: this.$t("Active"), value: "Active", color: "blue white--text" },
        {
          text: this.$t("Expired"),
          value: "Expired",
          color: "red darken-1 white--text",
        },
        { text: this.$t("ExpireSoon"), value: "ExpireSoon", color: "warning" },
      ];
    },
    headers() {
      var list = [
        { text: this.$t("products.product"), value: "productName" },
        { text: this.$t("customers.customerName"), value: "customerName" },
        { text: this.$t("activations.machineName"), value: "machineName" },
        {
          text: this.$t("activations.machineCode"),
          value: "machineCode",
          cellClass: "text_max_width",
        },
        { text: this.$t("activations.macAddress"), value: "macAddress" },
        {
          text: this.$t("activations.currentVersion"),
          value: "currentVersion",
        },
        {
          text: this.$t("activations.lastCheckDate"),
          value: "lastCheckDate",
          type: "date",
          format: "YYYY/MM/DD hh:mm a",
        },
        { text: this.$t("activations.extraInfo"), value: "extraInfo" },
        {
          text: this.$t("activeDate"),
          value: "createdUser.userName",
          secondLine:{
            value: "createdDate",
            format: "YYYY/MM/DD hh:mm A",
          }
        }, 
           {
         
          value: "licenseCode",
          align:' d-none'
           
        },
        //  {
        //   text: this.$t("modifiedBy"),
        //   value: "modifiedUser.userName",
        //   secondLine:{
        //     value: "modifiedDate",
        //     format: "YYYY/MM/DD hh:mm A",
        //   }
        // },
        {
          text: this.$t("activations.activeStatus"),
          value: "activeStatus",
          type: "select",
          items: this.activeStatusItems,
          chip: true,
        },
      ];
      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };

      actions.templates.push({
        type: "btn",
        icon: "mdi-clipboard-check-outline",
        text: this.$t("activations.copyLicense"),
        click: this.copyLicense,
      });
      if (this.$store.getters.isInRole(17)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
  },
  created() {
    this.loading = true;
    this.refreshTable();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    async copyLicense(item) {
      try {
        await navigator.clipboard.writeText(item.licenseCode);
        this.$toast.success(this.$t("CopySuccess"));
      } catch ($e) {
        this.$toast.error(this.$t("CannotCopy"));
      }
    },
    editItem(item) {
      this.editedIndex = this.activations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.activations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.activations[this.editedIndex];
      this.$axios
        .delete("Activations/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    filterChange(filter) {
      console.log(filter);
      this.filter = filter;
      this.refreshTable();
    },
    refreshTable() {
      this.$axios
        .get(
          "Activations/Get?" +
            (this.filter && this.filter.customerName
              ? "customerGuid=" + this.filter.customerName + "&"
              : "") +
            (this.filter && this.filter.productName
              ? "productGuid=" + this.filter.productName
              : "")
        )
        .then((response) => {
          this.activations = response.data.data;

          this.activations. forEach(element => {
        element.productName= element.productDisplayName +' - '+element.productName
      });


        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.activations[this.editedIndex];
          this.$axios
            .put("Activations/Update?guid=" + updatedItem.guid, this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
          Object.assign(this.users[this.editedIndex], this.editedItem);
        } else {
          this.$axios
            .post("Activations/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style>
.text_max_width {
  max-width: 160px;
}
</style>
